@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,regular,500,600,700,800,300italic,italic,500italic,600italic,700italic,800italic);
@import url(https://fonts.googleapis.com/css?family=Teko:300,regular,500,600,700);

html, body {
  margin: 0;
}

body {
  height: 100vh;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  font-family: 'Teko';
  background-color: #222;
}

.bowlingBall {
  width: 10rem;
  height: 10rem;
  background-color: #333;
  border-radius: 100%;
  position: relative;
  /* animation: rotX 2s forwards 0s infinite; */
}
.bowlingBall > .hole1 {
  position: relative;
  width: 1rem;
  height: 1rem;
  background-color: white;
  border-radius: 100%;
  top: 15%;
  left: 60%;
}
.bowlingBall > .hole2 {
  position: relative;
  width: 1rem;
  height: 1rem;
  background-color: white;
  border-radius: 100%;
  top: 15%;
  left: 45%;
}
.bowlingBall > .hole3 {
  position: relative;
  width: 1rem;
  height: 1rem;
  background-color: white;
  border-radius: 100%;
  top: 15%;
  left: 65%;
}

.title {
  display: flex;
  padding: 1rem 1.5rem;
  /* border: 5px solid white; */
  border-bottom: 5px solid white;
  border-radius: 0.5rem;
  justify-content: center;
}
.titleText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
}
.titleText > h1 {
  margin: 0;
  font-size: 4rem;
  line-height: 1.5ch;
  font-weight: 800;
  color: white;
}

.rankings {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}
.rankings > h1 {
  color: white;
  margin: 1rem 0 0 0;
}
.podiums {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 0.5rem;
}

.podiumParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.podiumParent > p {
  color: white;
  font-family: 'Open Sans';
  margin: 0 0 0.5rem 0;
}

.podium {
  width: 4rem;
  height: 5rem;
  background-color: white;
  color: black;
  font-family: 'Open Sans';
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.podium > p {
  margin: 0.5rem 0 0 0;
  font-size: 1.25rem;
  font-weight: 700;
}

.p1 {
  height: 7rem;
  background-color: gold;
}
.p2 {
  height: 5rem;
  background-color: silver;
}
.p3 {
  height: 3rem;
  background-color: orangered;
}

.ranks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
}

@keyframes rotX {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .title {
    transform: scale(0.75);
  }
  .ranks {
    gap: 1rem;
  }
}

form {
  background-color: white;
  display: flex;
  flex-direction: column;
}

form > input {
  width: 50%;
}

.adminButton {
  position: fixed;
  bottom: 2vh;
  right: 2vw;
  width: 5rem;
  height: 5rem;
  background-color: #3355ff;
  border-radius: 100%;
  font-size: 4rem;
  color: white;
  padding: 0;
}

.stat {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.stat > h1 {
  margin: 0;
  flex-flow: end;
}
.stat > h2 {
  margin: 0;
  font-size: 5rem;
}